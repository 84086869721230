import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';  // All section styles in one CSS file
import 'bootstrap/dist/css/bootstrap.min.css';

// Common Components
import Header from './components/header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Contact from './components/Contact';

// Pages for Routing
import PresidentMessages from './components/PresidentMessages';
import PrincipalMessage from './components/PrincipalMessage';
import SecretaryMessage from './components/SecretaryMessage';
import CSRActivities from './components/CSRActivities';
import ProjectBrochure from './components/ProjectBrochure';

// Main Page Components
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Legacy from './components/Legacy';
import VisionMission from './components/VisionMission';
import Courses from './components/Courses';
import AnnouncementsEvents from './components/AnnouncementsEvents';
import Testimonials from './components/Testimonials';
import Advantage from './components/Advantage';
import AwardsAccomplishments from './components/AwardsAccomplishments';
import FeeStructure from './components/FeeStructure';
import Gallery from './components/Gallery';

import GalleryDetail from './components/GalleryDetail';
import ScrollToTop from './components/ScrollToTop';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout component to wrap content with Header, Navbar, and Footer
const Layout = ({ children }) => (
  <>
    <Header />
    <Navbar />
    <div className="main-content">
      {children}
    </div>
    <Footer />
  </>
);

const App = () => {
  return (
    <Router>
    <ScrollToTop />
      <div className="App">
        <Routes>
          {/* Route for the homepage */}
          <Route path="/" element={
            <Layout>
              <HeroSection />
              <AboutUs />
              <Legacy />
              <VisionMission />
              <Courses />
              <AnnouncementsEvents />
              <Gallery />
              <AwardsAccomplishments />
              <FeeStructure />
              <Advantage />
              <Testimonials />
            </Layout>
          } />

              <Route>
                <Route path="/gallery" element={<Layout><Gallery /></Layout>} />
                <Route path="/gallery-detail" element={<Layout><GalleryDetail /></Layout>} />
              </Route>

          {/* Routes for specific pages, all wrapped with the Header, Navbar, and Footer */}
          <Route path="/our-messages/president" element={
            <Layout>
              <PresidentMessages />
            </Layout>
          } />
          <Route path="/our-messages/principal" element={
            <Layout>
              <PrincipalMessage />
            </Layout>
          } />
          <Route path="/our-messages/secretary" element={
            <Layout>
              <SecretaryMessage />
            </Layout>
          } />
          <Route path="/csr-activities/csr-activities" element={
            <Layout>
              <CSRActivities />
            </Layout>
          } />
          <Route path="/csr-activities/projectbrochure" element={
            <Layout>
              <ProjectBrochure />
            </Layout>
          } />

          <Route path="/Contact" element={
            <Layout>
              <Contact />
            </Layout>
          } />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
