import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import galleryImages from "./GalleryData"; // Import gallery data

const Gallery = () => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const handleGalleryClick = () => {
    setShowAll(true);
    navigate("/gallery-detail"); // Navigate to the detailed gallery page
  };

  const imageToDisplay = showAll ? galleryImages : galleryImages.slice(0, 4);

  return (
    <div className="gallery-section" id="gallery">
      <h2 className="gallery-title">LIFE AT B D HARNE</h2>
      <div className="gallery-grid">
        {imageToDisplay.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
      <button className="gallery-button" onClick={handleGalleryClick}>
        View Our Picture Gallery
      </button>
    </div>
  );
};

export default Gallery;
