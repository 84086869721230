import React, { useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import galleryImages from "./GalleryData"; // Assuming you store images in a separate file for reusability

function ControlledCarousel({ images, activeIndex, onSelect }) {
  return (
    <Carousel activeIndex={activeIndex} onSelect={onSelect}>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img src={image.src} alt={`Slide ${index + 1}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

const GalleryDetail = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCarouselSelect = (selectedIndex) => {
    setSelectedImageIndex(selectedIndex);
  };

  if (selectedImageIndex !== null) {
    return (
      <div className="carousel-overlay">
        <div className="carousel-container">
          <ControlledCarousel
            images={galleryImages}
            activeIndex={selectedImageIndex}
            onSelect={handleCarouselSelect}
          />
          <button className="close-carousel" onClick={() => setSelectedImageIndex(null)}>X</button>
        </div>
      </div>
    );
  }

  return (
    <div className="gallery-detail-section">
      <div className="gallery-detail-images">
        {galleryImages.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            onClick={() => handleImageClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default GalleryDetail;
