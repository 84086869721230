import React from 'react';

const FeeStructure = () => {
  return (
    <section className="fee-structure-section">
      <h2>FEE STRUCTURE</h2>
      <table className="fee-table">
        <thead>
          <tr>
            <th>Course</th>
            <th>Duration</th>
            <th>Fees</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Foundation Course</td>
            <td>6 Months</td>
            <td>₹30,000</td>
          </tr>
          <tr>
            <td>Advanced Course</td>
            <td>1 Year</td>
            <td>₹50,000</td>
          </tr>
          <tr>
            <td>Crash Course</td>
            <td>3 Months</td>
            <td>₹20,000</td>
          </tr>
          <tr>
            <td>Test Series</td>
            <td>2 Months</td>
            <td>₹10,000</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default FeeStructure;
