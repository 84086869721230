import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import BDHlogo from '../assets/images/BDH-logo11.png';
import aflogo from '../assets/images/aflogo1.png';

const Header = () => {
  return (
    <header className="header-section">
      <Container fluid>
        <Row className="align-items-center text-center text-md-left">

        {/* Logo for smaller screens */}
        <Col xs={11} className="text-center d-md-none">
        <a href="/#About.js">
            <Image src={BDHlogo} alt="Responsive Logo" className="logo" fluid />
            </a>
          </Col>

          {/* Logo on the left */}
          <Col xs={12} md={2} className="logo-column">
            <a href="/#About.js"> 
              <Image src={BDHlogo} alt="Left Logo" className="logo d-none d-md-block" // Hidden on smaller screens
              fluid/>
            </a>
          </Col>
          
          {/* Foundation Name, School Name, and Address */}
          <Col xs={12} md={8} className="text-column">
            <div className="header-text">
              <h4 className="foundation-name">AARADHYA FOUNDATION</h4>
              <h2 className="school-name">B.D. HARNE ENGLISH MEDIUM SCHOOL & JUNIOR COLLEGE</h2>
              <p className="school-address">Reg. No. E-1440(A)<br />
              At Post Shirodi, Khurd, Taluka Phulambri, Dist: Chhatrapati Sambhajinagar 431111.</p>
            </div>
          </Col>

          {/* Logo on the right */}
          <Col xs={12} md={2} className="logo-column">
          <a href="https://aaradhyafoundation.org.in/">
            <Image src={aflogo} alt="Right Logo" className="logo d-none d-md-block" // Hidden on smaller screens
              fluid/>
              </a>
          </Col>
          
        </Row>
      </Container>
    </header>
  );
};

export default Header;
