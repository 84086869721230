// Advantage.js
import React from 'react';

const advantage = [
  {
    title: 'Teaching Methodology',
    points: [
      'Concept & application based learning',
      'Interactive & engaging lectures',
      'Integrated approach focusing on competitive & school exams',
    ],
    icon: '📘', // Add relevant icons here
  },
  {
    title: 'Study Material',
    points: [
      'Well-researched & analyzed',
      'Comprehensive & meticulously designed',
      'Based on latest syllabus & exam pattern',
    ],
    icon: '📚',
  },
  {
    title: 'Regular Tests & Assessments',
    points: [
      'Detailed test analysis with error analysis report',
      'Simulated tests based on latest exam pattern',
      'Compete at national level with All India PDL Test Series',
    ],
    icon: '📝',
  },
  // Add more advantage sections here
];

const Advantage = () => {
  return (
    <section className="advantage-section">
      <h2 className="advantage-title">OUR ADVANTAGES</h2>
      <p className="advantage-subtitle">
        Stay ahead with all-round performance in your chosen stream
      </p>
      <div className="advantage-grid">
        {advantage.map((advantage, index) => (
          <div key={index} className="advantage-card">
            <div className="icon">{advantage.icon}</div>
            <h3>{advantage.title}</h3>
            <ul>
              {advantage.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Advantage;
