import React from 'react';

const Contact = () => {
  return (
    <section className="contact-section" id="contact">
      <h2 className="contact-title">Contact Us</h2>
      <p className="contact-subtitle">
        Reach out to us at any of our locations
      </p>
      <div className="contact-grid">
        <div className="contact-card">
          <div className="icon">🏫</div> {/* Add icons for each section */}
          <h4>School Address:</h4>
          <p>At Post Shirodi, Khurd, Taluka Phulambri, Dist: Chhatrapati Sambhajinagar 431111</p>
        </div>
        <div className="contact-card">
          <div className="icon">🏢</div>
          <h4>Headquarter Address:</h4>
          <p>LODHA SIGNET PALAVA, Lodha Signet Palava 2, 2-549, 5TH Floor Dombivli East 421204</p>
        </div>
        <div className="contact-card">
          <div className="icon">📞</div>
          <h4>Mobile No. & Mail ID:</h4>
          <p>+91 8779666070 <br />
             +91 7498774211 <br />
             info@aaradhyafoundation.org.in</p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
