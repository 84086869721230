// src/components/Footer.js
import React from 'react';
import logo from '../assets/images/logo removebg.png';
import BDHLogo from '../assets/images/BDH-logo1.png';
import { FaFacebookF, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <div className="footer-columns">
        {/* BDH Logo above Social Icons */}
        <div className="footer-BDHlogo-socials">
          <div className='footer-BDHlogo'>
          <a href="/#About.js">
            <p><img src={BDHLogo} alt="BDHLogo" className="footer-logo img-fluid" /></p> 
          </a>
          </div>

          {/* Social Icons */}
          <div className="footer-socials">
            <a href="https://www.facebook.com/people/Aaradhya-Foundation/61565444082517/" className="social-icon"><FaFacebookF /></a>
            <a href="https://www.instagram.com/learn.believe.achieve/" className="social-icon"><FaInstagram /></a>
            {/* <a href="https://google.com" className="social-icon"><FaGoogle /></a> */}
            <a href="https://www.youtube.com/@AaradhyaFoundation2024" className="social-icon"><FaYoutube /></a>
          </div>
        </div>

        {/* Column 2: School Address */}
        <div className="footer-address">
          <p><strong>School Address:</strong><br />
            At Post Shirodi, Khurd, Taluka Phulambri, <br />
            Dist: Chhatrapati Sambhajinagar 431111
          </p>
          <p><strong>Contact Us:</strong><br />
            +91 8779666070 <br />
            +91 7498774211 <br />
            info@aaradhyafoundation.org.in
          </p>
        </div>

        {/* Column 3: Headquarter Address */}
        <div className="footer-address">
          <p><strong>Headquarter Address:</strong><br />
            LODHA SIGNET PALAVA, Lodha Signet Palava 2,<br />
            2-549, 5TH Floor Dombivli East 421204
          </p>
          <p><strong>Contact Us:</strong><br />
            +91 8779666070 <br />
            +91 7498774211 <br />
            info@aaradhyafoundation.org.in
          </p>
        </div>
      </div>

      <div className="footer-designed">
      <p>© 2024 B. D. Harne English Medium School, All Rights Reserved <br></br>
        Designed and Managed by <br />
        <a href="https://bizonance.in">
        <img src={logo} alt="Logo" className="footer-logo" />
        </a>
      </p>
      </div>
    </footer>
  );
};

export default Footer;
