import React from 'react';
import secretaryImage from '../assets/images/StudentA.png'; // Update the path to your actual image

const SecretaryMessage = () => {
  return (
    <section className="secretary-message-section">
      <div className="secretary-message-container">
        <div className="secretary-image">
          <img src={secretaryImage} alt="Secretary" />
        </div>
        <div className="secretary-message-content">
          <h2>A Message from the Secretary</h2>
          <p>
            My Dear Students,<br />

            I am delighted to welcome the new entrants at our various programs and promise them a bright future with a meaningful existence. This is our sincere endeavor to make our young generation benefit from these changes in the realm of education. The future of the nation depends on the future of young generation. So in order to secure the future of our students it is important that they must get best education. Let us together transform our society through education. Join us in our noble journey, a journey towards perfection through education. In addition to academic quality, we value diversity because it is one of the most treasured tenets of the Institute.<br /><br/>

            As an emerging educational institute, we continue to strive to attract students, and faculty, from different backgrounds and provide them the kind of support they need to thrive. The young and promising students are most welcome to our institutes; we have dedicated ourselves to carve their career in such manner so as to enable them to successfully cope with the emerging challenges of life & professions. It’s my advice to students to get ahead of the world, define the rules and concepts and follow the right choice and victory shall be achieved. I welcome you to Aaradhya Foundations educational and hope that your journey at Aaradhya Foundation would be enjoyable, enriching and empowering.
            <br/><br/>

            So… thanks for joining us…<br/>
            let’s grow together for bright future.<br/><br/>
          </p>
          With best wishes!!
          <p>Mrs. Shraddha S. Harne</p>
        </div>
      </div>
    </section>
  );
};

export default SecretaryMessage;
