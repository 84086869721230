// src/components/Courses.js
import React from 'react';

const Courses = () => {
  return (
    <section className="courses" id="courses">
     <button className="admission-button">ADMISSION OPEN</button>
      <h2>STANDARD FROM 1<sup>st</sup> TO 10<sup>th</sup></h2>
      <div className="course-list">
        <div className="course-card">
          <h3>Unique Courses</h3>
          <ul>
            <li>ABACUS(Human Calculator)</li> 
            <li>VEDIC MATHS(Cultural techniques in India)</li> 
            <li>IAS JUNIOR(Competitive Exam.)</li>
            <li>GENERAL KNOWLEDGE(Designed Syllabus all agewise)</li>
          </ul>
        </div>
        <div className="course-card">
          <h3>Competitive Exams</h3>
          <ul>
          <li>SOF OLYMPIAD </li>
          <li>SILVER ZONE</li>
          <li>SCHOLARSHIP</li>
          <li>WISDOM</li>
          <li>NASO</li>
          <li>NSTSE</li>
          <li>UNIFIELD COUNCIL</li>
          </ul>
        </div>
        <div className="course-card">
          <h3>Smart Courses</h3>
          <ul>
          <li>ENGLISH SPEAKING CODING</li>
          <li>MENTAL MATHS(Solution in a Minute)</li>
          <li>PHONICS(Be the Best Reader)</li>
          <li>ROBOTICS HANDWRITING</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Courses;
