import React from 'react';
import aboutImage from '../assets/images/center.webp'; // Update with your actual image name

const AboutUs = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-us-content">
        <div className="text-content">
          <h2>ABOUT US</h2>
          <p>
            Foundation believes that education is both the means as well as the end to a better life: the means because it empowers an individual to earn his/her livelihood and the end because it increases one's awareness on a range of issues - from healthcare to appropriate social behavior to understanding one's rights - and in the process help him/her evolve as a better citizen.
            Education is the most effective tool which helps to build a strong foundation; enabling them to free themselves from the vicious cycle of ignorance, poverty, and disease.
            Aaradhya Foundation focuses on developing the potential of Child, women and girls to drive long-lasting equitable changes.
          </p>
          <p>
            Education is the key to empowering women and girls, which helps in bringing about social equality. The Girls' Education Program works to improve lives and provide opportunities for girls and women by increasing their participation in formal and alternative education systems.
          </p>
        </div>
        <div className="image-content">
          <img src={aboutImage} alt="About Us" />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
