import React from 'react';
import visionImage from '../assets/images/vision1.jpg'; // Replace with the actual image name
import missionImage from '../assets/images/mission1.png'; // Replace with the actual image name

const VisionMission = () => {
  return (
    <section className="vision-mission-section">
      <div className="vision-mission-container">
       {/* Vision Card */}
        <div className="vision-mission-card vision-card">
          <div className="card-image">
            <img src={visionImage} alt="Vision" />
          </div>
          
          <div className='card-content'>
            <h2>Vision</h2>
            <p>
              A world where every woman can freely use her voice, make her own decisions, and engage in economic opportunities is a society rooted in equality and empowerment. In this vision, women have access to quality education, healthcare, and career paths, enabling them to fully contribute to the economy and their communities. 
            </p>         
        </div>
      </div>

         {/* Mission Card */}
         <div className="vision-mission-card mission-card">
            <div className="card-image">
              <img src={missionImage} alt="Mission" />
            </div>
            <div className='card-content'>
              <h2>Mission</h2>      
              <p>
                Our mission is to empower women across cultures and ethnicities to stand up for justice, equality and change in all facets of life and society, in both local and global contexts. We accomplish this through Advocacy, Training and Rehabilitation. We are dedicated to empowering women to achieve their full economic potential by inspiring both women and men to become advocates, change makers, and leaders in their community.
              </p>
          </div>
          </div>

      </div>
    </section>
  );
};

export default VisionMission;
