// GalleryData.js
const galleryImages = [
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
   
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },{
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },{
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/group.jpeg"),
      alt: "Event 1",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    {
      src: require("../assets/images/hall-img.jpeg"),
      alt: "Event 2",
    },
    
    // Add more images as needed
  ];
  
  export default galleryImages;
  