import React from 'react';
import presidentImage from '../assets/images/StudentA.png'; // Update the path to your actual image

const PresidentMessages = () => {
  return (
    <section className="president-message-section">
      <div className="president-message-container">
        <div className="president-image">
          <img src={presidentImage} alt="President" />
        </div>
        <div className="president-message-content">
          <h2>President's Message</h2>
          <p>
            I believe that our understanding of a simple truth i.e. each student is important and every student can learn, shows our commitment to deliver quality in an impartial way. The words also reflect our willingness to change our own methods to transform each student into a lifelong learner	<br /><br />
          Aristotle said, <br />
          “ Educating the mind without educating the heart is no education at all”..<br /><br />

          How true and relevant are these words in the present circumstances and the way the world is unfolding itself !! The truth in these words inspires us to make our students understand the importance of practicing ‘self-development’ in truest sense. We are aware that each dimension of their personality needs to be nurtured at appropriate times as they move to higher standards year after year. And we shall effectively deliver the outcome.<br />

          Quietly moving forward towards realizing its vision "Creating Responsible Global Citizen", CISK humbly seeks your patronage and association. You are welcome to visit us & experience the promise of a brighter future.<br />

          For the all-around development of nation, one cannot neglect the role of NGO’S. At present so many NGO’S working in the country in the field of Education, Health care sector, social reform, Infrastructure development, gender equality, Removal of poverty-Il-literacy, Employment generation etc.<br />
          Aaradhya Foundation trust is also determined to work in the field of Education, Health care sector, social reform, Infrastructure development, gender equality, Removal of poverty-Il-literacy, Employment generation etc. to make India healthy as well as wealthy.<br />
          Aaradhya Foundation trust will remain always ready to work to fulfil its mission that is सर्वे सन्तु निरामय. To make every person happy by good health and wealth in this way making Wealthy India through Healthy India.
        </p>
        With best wishes!!
          <p>Mr. Shyam B. Harne</p>
        </div>
      </div>
    </section>
  );
};

export default PresidentMessages;
